import { template as template_716a96026c534b9588d31a9268531e47 } from "@ember/template-compiler";
const FKControlMenuContainer = template_716a96026c534b9588d31a9268531e47(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
