import { template as template_78c7046aaf364917b458f632aabd7310 } from "@ember/template-compiler";
const FKLabel = template_78c7046aaf364917b458f632aabd7310(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
